exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pl-js": () => import("./../../../src/pages/index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */),
  "component---src-pages-kontakt-pl-js": () => import("./../../../src/pages/kontakt.pl.js" /* webpackChunkName: "component---src-pages-kontakt-pl-js" */),
  "component---src-pages-o-nas-pl-js": () => import("./../../../src/pages/o-nas.pl.js" /* webpackChunkName: "component---src-pages-o-nas-pl-js" */),
  "component---src-pages-o-nas-proces-redakcyjny-pl-js": () => import("./../../../src/pages/o-nas/proces-redakcyjny.pl.js" /* webpackChunkName: "component---src-pages-o-nas-proces-redakcyjny-pl-js" */),
  "component---src-pages-o-nas-redakcja-pl-js": () => import("./../../../src/pages/o-nas/redakcja.pl.js" /* webpackChunkName: "component---src-pages-o-nas-redakcja-pl-js" */),
  "component---src-pages-polityka-prywatnosci-pl-js": () => import("./../../../src/pages/polityka-prywatnosci.pl.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-pl-js" */),
  "component---src-pages-regulamin-pl-js": () => import("./../../../src/pages/regulamin.pl.js" /* webpackChunkName: "component---src-pages-regulamin-pl-js" */),
  "component---src-templates-author-page-author-page-js": () => import("./../../../src/templates/AuthorPage/AuthorPage.js" /* webpackChunkName: "component---src-templates-author-page-author-page-js" */),
  "component---src-templates-blog-list-blog-list-js": () => import("./../../../src/templates/BlogList/BlogList.js" /* webpackChunkName: "component---src-templates-blog-list-blog-list-js" */),
  "component---src-templates-blog-post-blog-post-js": () => import("./../../../src/templates/BlogPost/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-blog-post-js" */)
}

